<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
            
        <div class="va-card">
          <div class="dash_card_body">
            <div class="row">
              <div class="col-lg-12">
                <div class="header_section">
                  <h4 class="menu_header">Users</h4>
                  <div class="">
                    <router-link :to="{ name: 'createuser'}">
                      <button class="btn btn-default custom_btn">
                        <i class="fas fa-user-plus"></i> New User
                      </button>
                    </router-link>
                  </div>

                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group has-search mt-1">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input type="text" v-model="search" @keyup="searchUsers()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..."   class="form-control" >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Role</th>
                        <th scope="col">Created By</th>
                        <th scope="col">Created On</th>
                        <th scope="col">Last Login At</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="user in users" :key="user.id">
                        <td>
                          <router-link :to="{ name: 'updateuser', params: { id:user.id }}">
                            {{user.name}}
                          </router-link>
                        </td>
                        <td><span>{{user.email}}</span></td>
                        <td><span>{{user.phone}}</span></td>
                        <td><span>{{user.role_name}}</span></td>
                        <td><span>{{user.created_by}}</span></td>
                        <td>
                          {{formatDate(user.created_at)}}
                        </td>
                        <td>
                          {{user.last_login_at}}
                        </td>
                        <td>
                          <div class = "btn-group">
                            <button class="btn custom_btn btn-sm dropdown-toggle'" type="button"  >
                              <span v-if="user.active == 1">
                                <i class='fa fa-check' style="color:green;"></i> 
                              </span>
                              <span v-else-if="user.active == 0">
                                <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                              </span>
                            </button>
                            <button type = "button" class = "btn btn-sm custom_btn btn-default dropdown-toggle" data-toggle = "dropdown" :id="'dropdownButton'+user.id" >
                              <span class = "caret"></span>
                              <span class = "sr-only">Toggle Dropdown</span>
                            </button>
                            <div class="dropdown-menu" :aria-labelledby="'dropdownButton'+user.id">
                              <a class="dropdown-item" @click="activateUser(user.id,1)" href="#">Activate</a>
                              <a class="dropdown-item" @click="activateUser(user.id,0)" href="#">Block</a>
                            </div>
                          </div>

                        </td>
                        <td><button class="btn btn-success btn-sm" @click="sendPasswordResetLink(user.id,user.name)"><span v-if="sending == user.id "><i class="fa fa-spinner fa-spin"></i></span> Reset Password</button></td>
                      </tr>

                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="8">
                          <div id="pagination" v-if="totalPages > 1">
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                </select>
                              </li>
                            
                            </ul>
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />

                          </div>
                          
                        </td>
                      </tr>
                    </tfoot>
                  </table>

                </div>
                <div v-if="loading" class="loading-cover">
                  <div class="loader"> </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins'
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import Pagination from '@/pages/pagination/pagination.vue';
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
    components: {
        Pagination,
    },
    data() {
        return {
            users: [],
            loading: false,
            page: 1,
            totalPages: 0,
            totalRecords: 0,
            recordsPerPage: 10,
            enterpageno: '',
            search:'',
            debounce: null,
            sending: null
        }
    },
    created: async function () {
        this.getUsers();
    },
    methods: {
      searchUsers() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getUsers();
            }, 1000)
        },
        async getUsers() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            let url = `${this.dashBaseUrl}/users`;
            url = new URL(url);
            url.searchParams.set('page', this.page);
            url.searchParams.set('size', this.recordsPerPage);
            url.searchParams.set('merchant_id',this.merchantID);
            if(this.search !==''){
              url.searchParams.set('search', this.search);
            }
            this.loading = true;
            this.axios.get(url,config).then((response) => {
                if (response.status === 200) {
                    this.loading = false;
                    this.users = response.data.data.data;
                    this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage)
                    this.totalRecords = response.data.data.total   
                }
            }).catch((err) => {
                console.log('error', err);
            })

        },
        async activateUser(id,status){
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
          const url = `${this.dashBaseUrl}/activate-user/${id}`
          const payload = {
            'status': status
          }
          const res = await this.axios.post(url,payload,config)
          if(res.status == 200){
            this.getUsers()
          }
        },
        async deleteUser(id) {
          this.spinner = id;
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/delete-user/${id}`;
            this.axios.post(url,{},config).then((response)=>{
                if(response.status === 200){
                   this.getStaff();
                    toast.success('staff deleted successfully');
                }
            }).catch((error)=>{
                if(error.response.status === 400){
                  // 
                }
            }).finally(()=> this.spinner = false);
          // 

        },
        sendPasswordResetLink(userId,name){
          this.sending = userId;
          const url = `${this.dashBaseUrl}/send-link`;
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
          const baseurl = window.location.origin +"/";
          const payload = {
            'user_id' : userId,
            'merchant_id': this.merchantID,
            'base_url': baseurl
          };
          this.axios.post(url,payload,config).then((response) => {
                if (response.status === 200) {
                  toast.success('link has been sent to user '+ name)   
                }
            }).catch((err) => {
                console.log('error', err);
            }).finally(()=> this.sending = null);
        },
        onPageChange(page) {
            this.page = page
            this.getUsers();
        },
        onChangeRecordsPerPage() {
            this.getUsers();
            // ds
        },
    },
    mixins: [ReusableFunctionMixins,ReusableDataMixins]
}
</script>
